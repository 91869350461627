import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button, TextField } from "@material-ui/core"
import { Link } from "gatsby"
import { API_ROOT, ENV } from "gatsby-env-variables"
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress"
import LOGO from "../images/invoke_logo3.png"
import { Snackbar as MaterialSnackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import { verifyMfaLogin } from "../httpRequests/mfa"

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState(false)
  const [severity, setSeverity] = useState("success")
  const [showMFAScreen, setShowMFAScreen] = useState(false)
  const [phone, setPhone] = useState("")
  const [sid, setSid] = useState("")
  const [code, setCode] = useState("")

  const onSubmit = e => {
    e.preventDefault()
    if (validate()) {
      setLoading(true)
      axios
        .post(`${API_ROOT}${ENV}/login`, {
          username: email,
          password: password,
        })
        .then(response => {
          setLoading(false)
          if (response.data.statusCode === 500) {
            setError(true)
            setSeverity("error")
            setMessage(
              "The email and password combination entered is not correct. Please contact the Invoke team if you continue to have difficulties."
            )
          } else {
            if (response.data.devMessage.mfa.enabled) {
              setShowMFAScreen(true)
              setPhone(response.data.devMessage.mfa.phone)
              setSid(response.data.devMessage.mfa.sid)
              window.localStorage.setItem("mfa", "1")
            } else {
              window.localStorage.setItem(
                "insightToken",
                response.data.devMessage.token
              )
              window.localStorage.setItem(
                "mfa",
                response.data.devMessage.mfa.enabled ? "1" : "0"
              )
              window.location.href = "/report-dashboard"
            }
          }
        })
    }
  }

  const validate = () => {
    if (email.trim() === "" || password.trim() === "") {
      setError(true)
      setSeverity("error")
      setMessage("Please fill out all fields!")
      return false
    } else {
      return true
    }
  }

  const onClickCloseSnackbar = () => {
    setError(false)
  }

  const verifyLogin = async () => {
    setLoading(true)
    const res = await verifyMfaLogin({
      sid: sid,
      code: code,
    })

    if (res.data.statusCode === 200) {
      window.localStorage.setItem("insightToken", res.data.devMessage.token)
      window.location.href = "/report-dashboard"
    } else {
      setError(true)
      setSeverity("error")
      setMessage(res.data.devMessage)
    }
    setLoading(false)
  }

  return (
    <Layout login>
      <Seo title="Login - INVOKE by FREE THE WORK" />
      <div className="bg login-page">
        <div className="container">
          <div className="top">
            <Link to="/">
              <img src={LOGO} alt="INVOKE" />
            </Link>
            {!showMFAScreen && <p>Login</p>}
          </div>

          {showMFAScreen ? (
            <div className="container mfa-login">
              <h2>Enter the code we texted you.</h2>
              <p>
                To securely log in, enter the verification code we sent to{" "}
                {phone}. If your phone number has changed, please contact our
                support team at{" "}
                <a href="mailto:support@freethework.com">
                  support@freethework.com
                </a>
                .
              </p>

              <div className="form">
                <TextField
                  fullWidth
                  placeholder="Enter verification code"
                  type="text"
                  onChange={e => setCode(e.target.value)}
                />
                <p onClick={onSubmit}>Resend code</p>
                <div className="actions">
                  <Button
                    variant="contained"
                    onClick={() => setShowMFAScreen(false)}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    className="submit"
                    onClick={verifyLogin}
                  >
                    {loading ? (
                      <CircularProgress size="24px" color="white" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={onSubmit}>
              <div className="form-wrapper">
                <TextField
                  type="email"
                  placeholder="Email"
                  fullWidth
                  onChange={e => setEmail(e.target.value)}
                />

                <div className="flex">
                  <TextField
                    type="password"
                    placeholder="Password"
                    fullWidth
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>

                <div className="flex actions">
                  <div className="flex">
                    <Link to="/forget-password">Forgot password</Link>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size="24px" color="white" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>

      <MaterialSnackbar
        open={error}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={onClickCloseSnackbar}
        disableWindowBlurListener
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={onClickCloseSnackbar}
          severity={severity}
        >
          {message}
        </MuiAlert>
      </MaterialSnackbar>
    </Layout>
  )
}

export default Login
